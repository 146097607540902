import React from 'react';
import { Helmet } from 'react-helmet';
import './App.css';

function App() {
  return (
    <div className="App">
      <Helmet>
        <title>One Credit Course & Internship on 5G - Krishtec</title>
        <meta 
          name="description" 
          content="Join Krishtec's One Credit Course and Internship on 5G technology. Gain industry insights, hands-on experience, and boost your career in 5G with expert-led training and practical internships." 
        />
        <meta 
          name="keywords" 
          content="One Credit Course, Internship on 5G, 5G technology course, Krishtec, 5G career, hands-on training, industry internships, 5G network learning, technology education" 
        />
        <meta name="author" content="Krishtec" />
        <meta property="og:title" content="One Credit Course & Internship on 5G - Krishtec" />
        <meta 
          property="og:description" 
          content="Explore Krishtec's unique One Credit Course and Internship on 5G technology to advance your career in the telecom sector." 
        />
        <meta property="og:url" content="https://www.krishtec.com/5g-course" />
        <meta property="og:type" content="website" />
      </Helmet>
      <header className="App-header">
        <h1>Welcome to Krishtec</h1>
        <p>Your Gateway to the Future of 5G Technology</p>
        <section>
          <h2>About Our One Credit Course on 5G</h2>
          <p>
            Our One Credit Course on 5G technology is meticulously designed to offer students comprehensive knowledge and hands-on experience with the latest in 5G networks. At Krishtec, we ensure that you are learning from industry experts and gaining the skills needed to excel in the rapidly evolving telecom industry.
          </p>
        </section>
        <section>
          <h2>Internship Opportunities in 5G Technology</h2>
          <p>
            Alongside the course, we provide exclusive internship opportunities where you can apply your 5G knowledge in real-world settings. Work on live projects, receive mentorship from professionals, and set yourself apart in the job market with practical experience that employers value.
          </p>
        </section>
        <section>
          <h2>Why Choose Krishtec?</h2>
          <ul>
            <li>Expert-led training sessions with industry veterans.</li>
            <li>Hands-on projects to reinforce learning.</li>
            <li>Exclusive internships providing practical experience.</li>
            <li>Networking opportunities with leading professionals in 5G.</li>
          </ul>
        </section>
        <footer>
          <p>Contact us at <a href="mailto:info@krishtec.com">info@krishtec.com</a> for more details or to enroll now!</p>
          <p>Follow us on social media: 
            <a href="https://www.facebook.com/krishtec" target="_blank" rel="noopener noreferrer">Facebook</a>, 
            <a href="https://www.linkedin.com/company/krishtec" target="_blank" rel="noopener noreferrer">LinkedIn</a>.
          </p>
        </footer>
      </header>
    </div>
  );
}

export default App;
